import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import AssessmentDesign from '../views/AssessmentDesign.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/', name: 'Home', component: Home },
    { path: '/:guid', name: 'Home', component: Home, props: route => ({ guid: route.params.guid })},
    { path: '/login', name: 'Login', component: Login },
    { path: '/AssessmentDesign/:id', name: 'AssessmentDesign', component: AssessmentDesign, props: route => ({ id: parseInt(route.params.id) }) },
    {
        path: '/about', name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})


// check for user token in storage, else check guid and then store, else return to home 

router.beforeEach((to, from, next) => {
    if (to.name != 'Home' && !localStorage.getItem('CurrentClient')) {
        next('/');
    }
    else next();
})

export default router
