











































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import LoginResponse from '@/models/LoginResponse';
import AssessmentDesignData from '@/models/AssessmentDesignData';
import AssessmentDesignTemplateData from '@/models/AssessmentDesignTemplateData';
import AssessmentDesignResponse from '@/models/AssessmentDesignResponse';
import api from '@/utils/api';

@Component({
    name: 'Home'
})
export default class Home extends Vue {
    @Prop(String) guid!: string;
    showError: boolean = false;
    errorMessage: string = '';
    loadingData: boolean = false;
    login: LoginResponse = { Token: '', GUID: '', ErrorMessage: '' };
    search: string = '';
    dialogCreateNew: boolean = false;
    dialogError: boolean = false;
    name: string = '';
    templateID: Number = 0;

    headers = [
        { text: 'Name', value: 'Name', align: 'start', width: '35%' },
        { text: 'Type', value: 'Template' },
        { text: 'Created Date', value: 'Created' },
        { text: 'Completed Date', value: 'Completed' },
        { text: 'Time (mins)', value: 'Time' },
        { text: '', value: 'Edit', sortable: false },
    ];

    rules = [
        (value: string) => !!value || 'Required.',
        (value: string) => (value || '').length <= 50 || 'Max 50 characters'
        ];

    allAssessmentDesigns: AssessmentDesignData[] = [];
    templateOptions: AssessmentDesignTemplateData[] = [];

    mounted() {
    }

    async created() {
            if (this.guid && this.guid != "") {
                let result = await api.get < LoginResponse > ('login?ClientGUID=' + this.guid);
                if (result.ok) {
                    if (result.data?.ErrorMessage == "") {
                        localStorage.setItem('CurrentClient', JSON.stringify(result.data));
                    }
                    else {
                        localStorage.removeItem('CurrentClient');
                        this.dialogError = true;
                        this.errorMessage = result.data?.ErrorMessage!;
                    }
                }
                else {
                    localStorage.removeItem('CurrentClient');
                    this.dialogError = true;
                    this.errorMessage = result.data?.ErrorMessage!;
                }
            }
            else {
                if (!localStorage.getItem('CurrentClient')) {
                    this.dialogError = true;
                    this.errorMessage = 'Invalid GUID';
                }
                
            }
        
        api.init(this);

        if (localStorage.getItem('CurrentClient')) {
            this.loadingData = true;
            await this.getAssessmentDesigns();
            this.loadingData = false;
        }
        else {
            this.dialogError = true;
        }
    };

    async getAssessmentDesigns() {
        this.login = JSON.parse(localStorage.getItem('CurrentClient')!);

        let assessmentDesignResponse = await api.get < AssessmentDesignResponse > ('?accessToken=' + this.login.Token);

        if (assessmentDesignResponse.ok) {
            this.allAssessmentDesigns = assessmentDesignResponse.data!.AssessmentDesigns;
            this.templateOptions = assessmentDesignResponse.data!.Templates;
            // Set the default templateID
            this.templateID = this.templateOptions[0].ID;
        }
        else {
            this.errorMessage = assessmentDesignResponse.error!;
            this.dialogError = true;
        }
    }

    async createNewAssessmentDesign() {
        if ((this.$refs.nameField! as any).validate()) {
            this.dialogCreateNew = false;
            this.loadingData = true;
            let createNewAssessmentDesignResponse = await api.post < number > ('?accessToken=' + this.login.Token + '&pTemplateID=' + this.templateID, this.name);
            if (createNewAssessmentDesignResponse.ok) {
                localStorage.removeItem('cardsStored');
                window.location.href = "/AssessmentDesign/" + createNewAssessmentDesignResponse.data;
            }
            else {
                this.dialogError = true;
                this.errorMessage = createNewAssessmentDesignResponse.error!;
            }
        }
    }

    editAssessmentDesign(item: AssessmentDesignData) {
        localStorage.removeItem('cardsStored');
        window.location.href = "/AssessmentDesign/" + item.ID;
    }
}

