





























































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import api from '@/utils/api';
import Card from '../models/Card';
import LoginResponse from '@/models/LoginResponse';

@Component({
    name: 'AssessmentDesign'
})
export default class AssessmentDesign extends Vue {
    @Prop(Number) id!: number;
    login: LoginResponse = { Token: '', GUID: '', ErrorMessage: '' };
    guid: String = '';
    showError: boolean = false;
    errorMessage: string = '';
    loadingData: boolean = false;
    savingData: boolean = false;
    emailAddress: string = '';

    active_tab: string = 'maybeTab';
    submitButtonDisabled: boolean = true;
    dialogSubmit: boolean = false;
    dialogMutuallyExclusiveCard: boolean = false;
    dialogError: boolean = false;

    Tabs = [{ name: 'yes', value: 'yesTab', ref: '#yesTab', pileNumberOfCards: 0, pileTimeOfCards: 0 }, { name: 'maybe', value: 'maybeTab', ref: '#maybeTab', pileNumberOfCards: 0, pileTimeOfCards: 0 }, { name: 'no', value: 'noTab', ref: '#noTab', pileNumberOfCards: 0, pileTimeOfCards: 0 }];
    Categories = [{}];

    allCards: Card[] = [];

    currentCardID: number = 0;
    currentPairedCardID: number = 0;
    currentDestinationPile: string = 'maybe';

    rules = [
        (value: string) => !!value || 'Required.',
        (value: string) => (value || '').length <= 150 || 'Max 150 characters',
        (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        }];

    async mounted() {
        this.loadingData = false;
    }

    async created() {
        this.login = JSON.parse(localStorage.getItem('CurrentClient')!);
        this.loadingData = true;
        api.init(this);
        await this.getcardItem();
        if (this.allCards[0].CardItem.AssessmentDesignTemplateID == 2) {
            this.Categories = [{ name: '', displayName: '' }];
        }
        else {
            this.Categories = [{ name: 'Behavioural', displayName: 'Behavioural' }, { name: 'Cognitive', displayName: 'Cognitive' }, { name: 'InterestsParent', displayName: 'Interests' }];
        }
        this.updateSummary();
    };

    // update the number of cards and time for each pile
    updateSummary() {
        // find the pile that the InterestsParent card is in, this pile will need to add 5 to the number of cards (to make 6 cards total under this parent)
        var InterestsParentPile = this.allCards.find(card => card.CardItem.Category === 'InterestsParent')?.Pile;

        this.Tabs.find(t => t.name === 'yes')!.pileNumberOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'yes').length + (InterestsParentPile === 'yes' ? 5 : 0);
        this.Tabs.find(t => t.name === 'maybe')!.pileNumberOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'maybe').length + (InterestsParentPile === 'maybe' ? 5 : 0);
        this.Tabs.find(t => t.name === 'no')!.pileNumberOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'no').length + (InterestsParentPile === 'no' ? 5 : 0);

        this.Tabs.find(t => t.name === 'yes')!.pileTimeOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'yes').reduce((n, { CardItem }) => n + CardItem.Time, 0);
        this.Tabs.find(t => t.name === 'maybe')!.pileTimeOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'maybe').reduce((n, { CardItem }) => n + CardItem.Time, 0);
        this.Tabs.find(t => t.name === 'no')!.pileTimeOfCards = this.allCards.filter(card => card.CardItem.IsGrouped === false && card.Pile === 'no').reduce((n, { CardItem }) => n + CardItem.Time, 0);

        // disable the submit button if there are any cards in the maybe pile
        if (this.Tabs.find(t => t.name === 'maybe')!.pileNumberOfCards == 0) {
            this.submitButtonDisabled = false;
        }
        else {
            this.submitButtonDisabled = true;
        }
    }

    moveCheck(e: any, choice: string) {
        // get card element using the name of the parent class
        const cardElement: HTMLElement = e.target.closest('.GPIcard');
        const cardId: number = Number(cardElement.id);
        // find the card within the data
        let card = this.allCards.find(card => card.CardItem.ID === cardId);
        let pairedCard = this.allCards.find(card => card.CardItem.PairedWithCardItemID === cardId);

        // set the current values globally, so they can be passed in the confirmation within the dialog
        this.currentCardID = cardId;
        this.currentDestinationPile = choice;

        // if the card has paired card in the pile it is moving to
        if (pairedCard != null && choice === 'yes' && pairedCard.Pile === choice) {
            this.currentPairedCardID = pairedCard.CardItem.ID;
            this.dialogMutuallyExclusiveCard = true;
        }
        else {
            this.move(cardId, choice);
        }
    }

    move(cardId: number, choice: string) {
        // find the card within the data
        let card = this.allCards.find(card => card.CardItem.ID === cardId);

        // update the pile it is in
        card!.Pile = choice;

        // if moving the InterestsParent card, all children cards need to also move pile within the data
        if (card?.CardItem.Category == 'InterestsParent') {
            this.moveChildCards(choice);
        }

        this.updateSummary();

        // save to local storage and db
        localStorage.setItem("cardsStored", JSON.stringify(this.allCards));
        this.saveChanges();
    }

    moveChildCards(destination: string) {
        this.allCards.filter(card => card.CardItem.IsGrouped === true).forEach(function (childCard) {
            childCard.Pile = destination;
            return childCard
        });
    }

    moveAll(category: string, from: string, to: string) {
        // if moving the InterestsParent card, all children cards need to also move pile within the data
        if (category === 'InterestsParent') {
            this.moveChildCards(to);
        }
        // loop through all the cards in the pile and category and update their pile
        this.allCards.filter(card => card.CardItem.Category === category && card.Pile === from).forEach(function (card) {
            card.Pile = to;
            return card;
        });

        this.updateSummary();

        // save to local storage and db
        localStorage.setItem("cardsStored", JSON.stringify(this.allCards));
        this.saveChanges();
    }

    async getcardItem() {
        let cardDataResponse = await api.get<Card[]>('AssessmentDesign?accessToken=' + this.login.Token  + '&AssessmentDesignID=' + this.id);

        if (cardDataResponse.ok) {
            this.allCards = cardDataResponse.data!;
        }
        else {
            this.dialogError = true;
            this.errorMessage = cardDataResponse.error!;
        }
    }

    async saveChanges() {
        let saveCardDataResponse = await api.post<number>('AssessmentDesign?accessToken=' + this.login.Token + '&AssessmentDesignID=' + this.id, this.allCards);

        if (!saveCardDataResponse.ok) {
            this.dialogError = true;
            this.errorMessage = saveCardDataResponse.error!;
        }
    }
    

    async submitAssessmentDesign() {
        if ((this.$refs.emailAddressField! as any).validate()) {
            this.dialogSubmit = false;
            let submitCardDataResponse = await api.post<string>('AssessmentDesign?accessToken=' + this.login.Token + '&AssessmentDesignID=' + this.id + '&Completed=true', this.emailAddress);

            if (submitCardDataResponse.ok) {
                window.location.href = '../';
            }
            else {
                this.dialogError = true;
                this.errorMessage = submitCardDataResponse.error!;
            }
        }
    }
}

