

















































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class App extends Vue {
    @Prop(String) guid!: string;
    drawer: boolean = false;
    showError: boolean = false;
    errorMessage: string = '';
    dialogPSYInstructions: boolean = false;
    dialog360Instructions: boolean = false;

    created() {
        this.$vuetify.theme.themes.light.primary = '#009EDB'
    }

    navHome() {
        let path = "/";
        if (this.$route.path !== path) this.$router.push(path)
    }
}

